/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Location } from '@reach/router'
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <section className="hero"> 
      <Header siteTitle={data.site.siteMetadata.title} />
      <Location>
      {({ location }) => {
        console.log(location)
        return <>{
          location.pathname === '/' 
          ? <div className="hero-content">
            <p className="hero-title">
            Every girl dreams to have her special day, to be  
              <strong>bride and shine</strong>
            </p>
          </div> 
          : <></>
        }</>
      }}
    
        
        </Location>
        
        <main>{children}</main>
      </section>  
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
