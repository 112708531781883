import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const HeaderContainer = styled.div`
  display: flex;  
  position: relative;
  margin: 32px 32px 0 32px;
  padding-bottom: 3rem;

  a, Link {
    font-weight: 500;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    color: white;
    padding-right: 24px;
  }

  .homeLink a {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  @media (min-width: 720px) { 
    .homeLink a {
      margin-left: 24px;
    }
  }

  .secondaryLinks {
    margin-left: auto;
  }
`;

const Header = ({ siteTitle }) => (
  <HeaderContainer>
    <div className="homeLink">
      <Link to="/">
        {siteTitle}
      </Link>
    </div>

    <div className="secondaryLinks">
      <Link to="/">
        Home
      </Link>
      <a
        href="https://www.facebook.com/Bride-and-Shine-106446664245012/?hc_ref=ARRk_urGBIn1jfaChy3O1_4KS7di_8NPAJINfVBgI2YvT7KDcRkFe4DicjerUgtme5U&fref=nf&__tn__=kC-R"
        target="_blank"
      >
       Facebook
      </a>
    </div>
  </HeaderContainer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
